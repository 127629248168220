var site = site || {};

(function ($, Drupal) {
  Drupal.behaviors.boutiqueQuickshopMediaV1 = {
    attached: false,
    attach: function (context) {
      var $modules = $('.js-boutique-quickshop-media--v1', context);
      var productsArray = page_data['custom-mpp'] && page_data['custom-mpp'].products ? page_data['custom-mpp'].products : [];
      var isMobile = parseInt(Unison.fetch.now().width) === parseInt(Unison.fetch.all().small);
      var tempCounter = 0;

      $modules.each(function () {
        var $this = $(this);
        var $launcher = $('.js-video-launcher', $this);
        var $play = $('.js-play-button-icon', $this);
        var $productsDesktop = $('.js-boutique-quickshop-media__quickshop-desktop', $this);

        $this.addClass('boutique-quickshop-media__' + tempCounter);
        tempCounter++;
        if ($play.length) {
          $play.off('click').on('click', function (event) {
            event.preventDefault();
            $launcher.trigger('click');
          });
        }

        function initQSDesktop() {
          $productsDesktop.each(function () {
            var $product = $(this);
            var productId = $product.data('product-id');
            var productData = _.find(productsArray, function (p) {
              return p.PRODUCT_ID === productId;
            });
            var _qsInit = function () {
              if (typeof site.product !== 'undefined' && typeof site.product.view !== 'undefined') {
                if (typeof site.product.view.equalRowHeight !== 'undefined') {
                  site.product.view.equalRowHeight($product);
                }
                if (typeof site.product.view.brief !== 'undefined') {
                  site.product.view.brief({
                    containerNode: this,
                    productData: productData
                  });
                }
              }
              $('.qs_link', $product).off().on('click', function (event) {
                event.preventDefault();
                site.quickshop(productData);
              });
            };

            _qsInit();
            $(window).resize(_.debounce(function () {
              _qsInit();
            }, 250));
          });
        }

        function initQSMobile() {
          // initialize individual product views
          var productsData = productsArray;

          for (var i = 0; i < productsData.length; i++) {
            productsData[i] = site.shadeFilter.loadProductFilterData(productsData[i]);
            site.productView.mobileMppItem(productsData[i]);
          }
        }

        isMobile ? initQSMobile() : initQSDesktop();
      });
    }
  };
})(jQuery, Drupal);
